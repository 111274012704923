// Welcome.js

import React from "react";

const Welcome = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      {/* Nouvelle image ajoutée ici */}
      {/* <img
                src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/Programme_2024.png'
                alt='Programme 2024'
                className='w-22 mb-5' // Ajoutez des classes pour ajuster la taille et la marge selon besoin
            /> */}
      <img
        src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/mr+logo+(3).png"
        alt="Welcome"
        className="w-26.25 h-16 mb-5"
      />
      <div className="text-left">
        <h1 className="text-xl font-bold mb-2 ml-5 mr-5">
          Bonjour, je suis Victor, l'IA du Mouvement Réformateur !
        </h1>
        <p className="text-l ml-5 mr-5">
          Vous avez des questions sur le programme du gouvernement du Mouvement
          réformateur, celui du gouvernement fédéral (Arizona), du gouvernement
          wallon (Azur) ou encore de la Fédération Wallonie-Bruxelles ?
          Sélectionner celui qui vous intéressent et notre intelligence
          artificielle Victor IA se fera un plaisir de vous éclairer
        </p>
        <p className="text-m ml-5 mr-5"></p>
      </div>
    </div>
  );
};

export default Welcome;
// Welcome.js
// Welcome.js
